/**
 * Convert a elasping or not time value to milliseconds, from a given date.
 * date can be new Date(), or server date.
 */
export const timeValueToMilliseconds = (timeValue, date) => {
    if (timeValue instanceof Date) {
        return timeValue.getTime() - date.getTime();
    }
    return timeValue;
};
