/* eslint-disable @typescript-eslint/no-explicit-any */
import { Encoder as BaseEncoder, Decoder as BaseDecoder, PacketType } from 'socket.io-parser';
import { denormalize, normalize } from './serializer';
const shouldTransformPacket = (packet) => {
    return (packet.type === PacketType.EVENT ||
        packet.type === PacketType.ACK) && Array.isArray(packet.data);
};
export class Encoder extends BaseEncoder {
    encode(obj) {
        if (shouldTransformPacket(obj)) {
            obj.data = obj.data.map(arg => normalize(arg));
        }
        return super.encode(obj);
    }
}
export class Decoder extends BaseDecoder {
    constructor() {
        super();
        this.baseDecoder = new BaseDecoder();
        this.baseDecoder.on('decoded', packet => {
            if (shouldTransformPacket(packet)) {
                packet.data = packet.data.map(arg => denormalize(arg));
            }
            super.emitReserved('decoded', packet);
        });
    }
    add(obj) {
        this.baseDecoder.add(obj);
    }
}
