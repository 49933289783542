var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Column, Entity, Index, JoinColumn, OneToOne, PrimaryColumn } from 'typeorm';
import { Type } from 'class-transformer';
import { ColumnUUID } from '../custom-typeorm';
import HostedGame from './HostedGame';
import { BOARD_DEFAULT_SIZE } from '../../game-engine';
import { IsBoolean, IsIn, IsNumber, IsObject, IsOptional, IsUUID, Max, Min, Validate, ValidateNested } from 'class-validator';
import { Expose } from '../class-transformer-custom';
import { HostedGameOptionsTimeControl, HostedGameOptionsTimeControlByoYomi, HostedGameOptionsTimeControlFischer } from './HostedGameOptionsTimeControl';
import { BoardsizeEligibleForRanked, FirstPlayerEligibleForRanked, SwapRuleEligibleForRanked } from '../validator/OptionsEligibleForRanked';
export const DEFAULT_BOARDSIZE = BOARD_DEFAULT_SIZE;
export const MIN_BOARDSIZE = 1;
export const MAX_BOARDSIZE = 42;
let HostedGameOptions = class HostedGameOptions {
    constructor() {
        this.ranked = false;
        /**
         * Defaults to BOARD_DEFAULT_SIZE.
         */
        this.boardsize = DEFAULT_BOARDSIZE;
        /**
         * Who plays first.
         * null: random (default)
         * 0: Host begins
         * 1: Opponent or bot begins
         */
        this.firstPlayer = null;
        /**
         * Whether the swap rule is enabled or not.
         * Should be true by default for 1v1 games.
         */
        this.swapRule = true;
        /**
         * Which opponent type I want.
         */
        this.opponentType = 'player';
        /**
         * If set, only this player can join.
         * If it is a bot player, it will automatically join.
         */
        this.opponentPublicId = null;
    }
};
__decorate([
    PrimaryColumn(),
    __metadata("design:type", Number)
], HostedGameOptions.prototype, "hostedGameId", void 0);
__decorate([
    OneToOne(() => HostedGame, hostedGame => hostedGame.gameOptions),
    JoinColumn({ name: 'hostedGameId' }),
    __metadata("design:type", typeof (_a = typeof HostedGame !== "undefined" && HostedGame) === "function" ? _a : Object)
], HostedGameOptions.prototype, "hostedGame", void 0);
__decorate([
    Column(),
    Expose(),
    IsBoolean(),
    Validate(BoardsizeEligibleForRanked),
    Validate(FirstPlayerEligibleForRanked),
    Validate(SwapRuleEligibleForRanked),
    __metadata("design:type", Boolean)
], HostedGameOptions.prototype, "ranked", void 0);
__decorate([
    Min(MIN_BOARDSIZE),
    Max(MAX_BOARDSIZE),
    Column({ type: 'smallint' }),
    Expose(),
    __metadata("design:type", Number)
], HostedGameOptions.prototype, "boardsize", void 0);
__decorate([
    IsNumber(),
    IsOptional(),
    Column({ type: 'smallint', nullable: true }),
    Expose(),
    __metadata("design:type", Object)
], HostedGameOptions.prototype, "firstPlayer", void 0);
__decorate([
    IsBoolean(),
    Column(),
    Expose(),
    __metadata("design:type", Boolean)
], HostedGameOptions.prototype, "swapRule", void 0);
__decorate([
    IsIn(['player', 'ai']),
    Column({ length: 15 }),
    Index(),
    Expose(),
    __metadata("design:type", String)
], HostedGameOptions.prototype, "opponentType", void 0);
__decorate([
    IsUUID(),
    IsOptional(),
    ColumnUUID({ nullable: true }),
    Expose(),
    __metadata("design:type", Object)
], HostedGameOptions.prototype, "opponentPublicId", void 0);
__decorate([
    Column({ type: 'json' }),
    Expose(),
    IsObject(),
    ValidateNested(),
    Type((type) => {
        // Made by hand because discriminator is buggy, waiting for: https://github.com/typestack/class-transformer/pull/1118
        switch (type === null || type === void 0 ? void 0 : type.object.timeControl.type) {
            case 'fischer': return HostedGameOptionsTimeControlFischer;
            case 'byoyomi': return HostedGameOptionsTimeControlByoYomi;
            default: return HostedGameOptionsTimeControl;
        }
    }),
    __metadata("design:type", Object)
], HostedGameOptions.prototype, "timeControl", void 0);
HostedGameOptions = __decorate([
    Entity()
], HostedGameOptions);
export default HostedGameOptions;
/**
 * Recreate a new game options instance to attribute to a new game with same options.
 * Cannot reuse same instance because two game cannot share same instance (one to one).
 */
export const cloneGameOptions = (gameOptions) => {
    const clone = new HostedGameOptions();
    clone.ranked = gameOptions.ranked;
    clone.boardsize = gameOptions.boardsize;
    clone.firstPlayer = gameOptions.firstPlayer;
    clone.swapRule = gameOptions.swapRule;
    clone.opponentType = gameOptions.opponentType;
    clone.opponentPublicId = gameOptions.opponentPublicId;
    clone.timeControl = gameOptions.timeControl;
    return clone;
};
