import { createHead, useHead, useSeoMeta } from '@unhead/vue';
import { seo, jsonLd } from '../../shared/app/seo';
export const head = createHead();
export const useJsonLd = (jsonLd) => {
    useHead({
        script: [
            {
                key: 'specific',
                type: null === jsonLd ? '' : 'application/ld+json',
                textContent: null === jsonLd ? '[]' : JSON.stringify(jsonLd),
            },
        ],
    });
};
export const useHeadDefault = () => {
    useSeoMeta({
        robots: 'index',
        titleTemplate: (title) => title ? `${title} - ${seo.title}` : seo.title,
        description: seo.description,
        ogType: 'website',
        ogUrl: jsonLd.url,
        ogTitle: seo.title,
        ogDescription: seo.description,
        ogImage: `${jsonLd.url}/images/social-preview.jpg`,
        ogSiteName: seo.title,
        twitterCard: 'summary_large_image',
    });
    useJsonLd(null);
};
