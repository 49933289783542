import { io } from 'socket.io-client';
import * as CustomParser from '@shared/app/socketCustomParser';
import { defineStore } from 'pinia';
import useAuthStore from './authStore';
import { watch, ref } from 'vue';
/**
 * Use global io if overriden, else use normal io.
 * Used for functionnal tests, to allow using a mocked io.
 */
const getIo = () => {
    var _a;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return ((_a = window.io) !== null && _a !== void 0 ? _a : io);
};
const useSocketStore = defineStore('socketStore', () => {
    const socket = getIo()({
        parser: CustomParser,
        autoConnect: false, // connect once player is logged in at least as guest
    });
    const joinRoom = (room) => socket.emit('joinRoom', room);
    const leaveRoom = (room) => socket.emit('leaveRoom', room);
    const connected = ref(false);
    /*
     * Reconnect socket when logged in player changed
     */
    const reconnectSocket = () => {
        socket.disconnect().connect();
    };
    const authStore = useAuthStore();
    watch(() => authStore.loggedInPlayer, reconnectSocket);
    socket.on('connect', () => {
        connected.value = true;
    });
    socket.on('disconnect', () => {
        connected.value = false;
    });
    return {
        socket,
        connected,
        joinRoom,
        leaveRoom,
        reconnectSocket,
    };
});
export default useSocketStore;
