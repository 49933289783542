var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Column, Entity, JoinColumn, ManyToOne, PrimaryColumn } from 'typeorm';
import HostedGame from './HostedGame';
import Player from './Player';
import { Expose } from '../class-transformer-custom';
let HostedGameToPlayer = class HostedGameToPlayer {
};
__decorate([
    PrimaryColumn(),
    __metadata("design:type", Number)
], HostedGameToPlayer.prototype, "hostedGameId", void 0);
__decorate([
    ManyToOne(() => HostedGame, hostedGame => hostedGame.hostedGameToPlayers),
    JoinColumn(),
    __metadata("design:type", typeof (_a = typeof HostedGame !== "undefined" && HostedGame) === "function" ? _a : Object)
], HostedGameToPlayer.prototype, "hostedGame", void 0);
__decorate([
    Column(),
    __metadata("design:type", Number)
], HostedGameToPlayer.prototype, "playerId", void 0);
__decorate([
    ManyToOne(() => Player),
    Expose(),
    __metadata("design:type", typeof (_b = typeof Player !== "undefined" && Player) === "function" ? _b : Object)
], HostedGameToPlayer.prototype, "player", void 0);
__decorate([
    PrimaryColumn('smallint'),
    __metadata("design:type", Number)
], HostedGameToPlayer.prototype, "order", void 0);
HostedGameToPlayer = __decorate([
    Entity()
], HostedGameToPlayer);
export default HostedGameToPlayer;
