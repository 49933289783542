export default class Move {
    constructor(row, col, playedAt = new Date()) {
        this.row = row;
        this.col = col;
        this.playedAt = playedAt;
    }
    static special(specialMoveType, playedAt = new Date()) {
        const move = new Move(-1, -1, playedAt);
        move.specialMoveType = specialMoveType;
        return move;
    }
    static swapPieces(playedAt = new Date()) {
        return Move.special('swap-pieces', playedAt);
    }
    static pass(playedAt = new Date()) {
        return Move.special('pass', playedAt);
    }
    getSpecialMoveType() {
        return this.specialMoveType;
    }
    getPlayedAt() {
        return this.playedAt;
    }
    static rowToNumber(row) {
        return String(row + 1);
    }
    static colToLetter(col) {
        /** letter(4) => "e" */
        const letter = (n) => String.fromCharCode(97 + n);
        return col < 26
            ? letter(col)
            : letter(Math.floor(col / 26) - 1) + letter(col % 26);
    }
    /**
     * @throws Error if move is not like "f6", or like "swap-pieces"
     */
    static fromString(moveString) {
        if ('swap-pieces' === moveString) {
            return Move.swapPieces();
        }
        if ('pass' === moveString) {
            return Move.pass();
        }
        const match = moveString.match(/^"?([a-z]{1,2})(\d{1,2})"?$/);
        if (null === match) {
            throw new Error(`Invalid move coords: "${moveString}", expected a move like "c2"`);
        }
        const [, letter, number] = match;
        const letterCol = 1 === letter.length
            ? letter.charCodeAt(0) - 97
            : letter.charCodeAt(1) - 97 + 26 * (letter.charCodeAt(0) - 97 + 1);
        return new Move(parseInt(number, 10) - 1, // "1" is 0
        letterCol);
    }
    toString() {
        if (this.specialMoveType) {
            return this.specialMoveType;
        }
        return Move.colToLetter(this.col) + Move.rowToNumber(this.row);
    }
    sameAs(move) {
        if (this.specialMoveType !== move.specialMoveType) {
            return false;
        }
        if (undefined !== this.specialMoveType) {
            return this.specialMoveType === move.specialMoveType;
        }
        return this.row === move.row && this.col === move.col;
    }
    clone() {
        if ('swap-pieces' === this.specialMoveType) {
            return Move.swapPieces(this.playedAt);
        }
        if ('pass' === this.specialMoveType) {
            return Move.pass(this.playedAt);
        }
        return new Move(this.row, this.col, this.playedAt);
    }
    cloneMirror() {
        if ('swap-pieces' === this.specialMoveType) {
            return Move.swapPieces(this.playedAt);
        }
        if ('pass' === this.specialMoveType) {
            return Move.pass(this.playedAt);
        }
        return new Move(this.col, this.row, this.playedAt);
    }
    toData() {
        return {
            row: this.row,
            col: this.col,
            specialMoveType: this.specialMoveType,
            playedAt: this.playedAt,
        };
    }
    static fromData(moveData) {
        if (moveData.specialMoveType) {
            return Move.special(moveData.specialMoveType);
        }
        return new Move(moveData.row, moveData.col, moveData.playedAt);
    }
    /**
     * Returns moves like "a1 swap-pieces b4 c5 pass c6 ..."
     */
    static movesAsString(moves) {
        return moves
            .map(move => move.toString())
            .join(' ');
    }
}
