var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Column, Entity, Index, JoinTable, ManyToMany, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import Player from './Player';
import HostedGame from './HostedGame';
import { Expose } from '../class-transformer-custom';
let Rating = class Rating {
};
__decorate([
    PrimaryGeneratedColumn(),
    __metadata("design:type", Number)
], Rating.prototype, "id", void 0);
__decorate([
    ManyToOne(() => Player),
    Expose(),
    __metadata("design:type", typeof (_a = typeof Player !== "undefined" && Player) === "function" ? _a : Object)
], Rating.prototype, "player", void 0);
__decorate([
    ManyToMany(() => HostedGame, hostedGame => hostedGame.ratings),
    JoinTable(),
    __metadata("design:type", Array)
], Rating.prototype, "games", void 0);
__decorate([
    Column(),
    Expose(),
    __metadata("design:type", Object)
], Rating.prototype, "category", void 0);
__decorate([
    Column(),
    Expose(),
    __metadata("design:type", typeof (_b = typeof Date !== "undefined" && Date) === "function" ? _b : Object)
], Rating.prototype, "createdAt", void 0);
__decorate([
    Column({ type: 'float' }),
    Expose(),
    __metadata("design:type", Number)
], Rating.prototype, "rating", void 0);
__decorate([
    Column({ type: 'float' }),
    Expose(),
    __metadata("design:type", Number)
], Rating.prototype, "deviation", void 0);
__decorate([
    Column({ type: 'float' }),
    Expose(),
    __metadata("design:type", Number)
], Rating.prototype, "volatility", void 0);
__decorate([
    Column({ type: 'float', nullable: true }),
    Expose(),
    __metadata("design:type", Number)
], Rating.prototype, "ratingChange", void 0);
Rating = __decorate([
    Entity(),
    Index(['player', 'category', 'createdAt'])
], Rating);
export default Rating;
