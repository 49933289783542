import { setDefaultOptions as setDateFnsDefaultOptions } from 'date-fns';
import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import { loadDateFnsLocale } from './dateFns';
import { availableLocales } from './availableLocales';
export { availableLocales, };
const localStorageKey = 'selectedLocale';
const getPlayerSelectedLocale = () => localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem(localStorageKey);
const getSupportedBrowserLocale = () => {
    const availableLocalesCodes = Object.keys(availableLocales);
    for (const navigatorLocale of navigator.languages) {
        if (availableLocalesCodes.includes(navigatorLocale)) {
            return navigatorLocale;
        }
    }
    return null;
};
/**
 * Get locale that should be used.
 * Either player selected it, or prefered by browser.
 */
export const autoLocale = () => { var _a, _b; return (_b = (_a = getPlayerSelectedLocale()) !== null && _a !== void 0 ? _a : getSupportedBrowserLocale()) !== null && _b !== void 0 ? _b : 'en'; };
const setI18nLanguage = (locale) => {
    i18next.changeLanguage(locale);
    document.querySelector('html').setAttribute('lang', locale);
};
const loadLocaleMessages = async (locale) => {
    const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`);
    // set locale and locale message
    i18next.addResourceBundle(locale, 'translation', messages.default);
};
/**
 * Switch app locale.
 *
 * @param locale "en", "fr", "en-US", ...
 * @param remember Default true, remember this choice. Should be true when locale is explicitely selected by user,
 *                 or false if locale still should be detected from browser.
 */
export const setLocale = async (locale, remember = true) => {
    if (remember) {
        localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem(localStorageKey, locale);
    }
    setDateFnsDefaultOptions({ locale: await loadDateFnsLocale(locale) });
    await loadLocaleMessages(locale);
    setI18nLanguage(locale); // Should be last so when "languageChanged" is emitted, date fns also has the new locale
};
(async () => {
    // Load current locale translation as soon as possible to prevent text blinking
    loadLocaleMessages(autoLocale());
    // Always load en locales for fallbacks (in case of missing translation)
    loadLocaleMessages('en');
    await i18next
        .use(HttpBackend)
        .init({
        lng: autoLocale(),
        debug: 'development' === process.env.NODE_ENV,
        fallbackLng: 'en',
    });
    setLocale(autoLocale(), false);
})();
