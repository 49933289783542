var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Transform } from 'class-transformer';
import { Expose, GROUP_DEFAULT, plainToInstance } from '../class-transformer-custom';
import Player from './Player';
export default class OnlinePlayers {
    constructor() {
        this.players = {};
    }
}
__decorate([
    Expose(),
    __metadata("design:type", Number)
], OnlinePlayers.prototype, "totalPlayers", void 0);
__decorate([
    Expose(),
    Transform(({ value }) => {
        const players = {};
        for (const publicId in value) {
            players[publicId] = plainToInstance(Player, value[publicId]);
        }
        return players;
    }, { toClassOnly: true, groups: [GROUP_DEFAULT] }),
    __metadata("design:type", Object)
], OnlinePlayers.prototype, "players", void 0);
