import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "menu-top navbar bg-body-tertiary" };
const _hoisted_2 = { class: "container-fluid justify-content-space-between" };
const _hoisted_3 = {
    key: 0,
    class: "text-body-secondary"
};
const _hoisted_4 = { class: "my-turn-notif" };
const _hoisted_5 = { class: "nav-player-item" };
import useAuthStore from '@client/stores/authStore';
import { storeToRefs } from 'pinia';
import useMyGamesStore from '../../../stores/myGamesStore';
import { useRouter } from 'vue-router';
import { BIconPersonFill, BIconHexagonFill, BIconHexagon } from 'bootstrap-icons-vue';
import AppPseudo from '../AppPseudo.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AppHeader',
    setup(__props) {
        const { loggedInPlayer } = storeToRefs(useAuthStore());
        /* global SITE_TITLE_SUFFIX */
        // @ts-ignore: SITE_TITLE_SUFFIX replaced at build time by webpack.
        const siteTitleSuffix = SITE_TITLE_SUFFIX;
        /*
         * My turn notification
         */
        const { myTurnCount, mostUrgentGame } = storeToRefs(useMyGamesStore());
        const router = useRouter();
        const goToMostUrgentGame = () => {
            if (null === mostUrgentGame.value) {
                return;
            }
            router.push({
                name: 'online-game',
                params: {
                    gameId: mostUrgentGame.value.publicId,
                },
            });
        };
        /**
         * No game => filled
         * Turn to play => filled
         * No turn to play, but I have current game => empty
         */
        const isFilled = () => null === mostUrgentGame.value || myTurnCount.value > 0;
        /**
         * No game => grey
         * Most urgent game => my color in this game
         */
        const color = () => null === mostUrgentGame.value
            ? 'text-secondary'
            : (0 === mostUrgentGame.value.myColor
                ? 'text-danger'
                : 'text-primary');
        return (_ctx, _cache) => {
            const _component_router_link = _resolveComponent("router-link");
            return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_router_link, {
                        to: "/",
                        class: "navbar-brand",
                        "aria-label": "Go to PlayHex lobby"
                    }, {
                        default: _withCtx(() => [
                            _cache[1] || (_cache[1] = _createTextVNode("Play")),
                            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-danger" }, "Hex", -1)),
                            (_unref(siteTitleSuffix))
                                ? (_openBlock(), _createElementBlock("small", _hoisted_3, " - " + _toDisplayString(_unref(siteTitleSuffix)), 1))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    }),
                    _createElementVNode("span", _hoisted_4, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(isFilled() ? _unref(BIconHexagonFill) : _unref(BIconHexagon)), {
                            class: _normalizeClass(["hexagon", color()])
                        }, null, 8, ["class"])),
                        _createElementVNode("a", {
                            href: "#",
                            onClick: _cache[0] || (_cache[0] = ($event) => (goToMostUrgentGame())),
                            class: _normalizeClass(isFilled() ? 'text-white' : 'text-body')
                        }, _toDisplayString(_unref(myTurnCount)), 3)
                    ]),
                    _createElementVNode("p", _hoisted_5, [
                        (_unref(loggedInPlayer))
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createVNode(_unref(BIconPersonFill)),
                                _cache[3] || (_cache[3] = _createElementVNode("span", null, " ", -1)),
                                _createVNode(_component_router_link, {
                                    to: { name: 'player', params: { slug: _unref(loggedInPlayer).slug } }
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(AppPseudo, { player: _unref(loggedInPlayer) }, null, 8, ["player"])
                                    ]),
                                    _: 1
                                }, 8, ["to"])
                            ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode("logging in…")
                            ], 64))
                    ])
                ])
            ]));
        };
    }
});
