var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Expose } from '../class-transformer-custom';
import { Move as EngineMove } from '../../game-engine';
export default class Move {
}
__decorate([
    Expose(),
    __metadata("design:type", Number)
], Move.prototype, "row", void 0);
__decorate([
    Expose(),
    __metadata("design:type", Number)
], Move.prototype, "col", void 0);
__decorate([
    Expose(),
    __metadata("design:type", Object)
], Move.prototype, "specialMoveType", void 0);
__decorate([
    Expose(),
    __metadata("design:type", typeof (_a = typeof Date !== "undefined" && Date) === "function" ? _a : Object)
], Move.prototype, "playedAt", void 0);
export const fromEngineMove = (engineMove) => {
    const move = new Move();
    move.row = engineMove.row;
    move.col = engineMove.col;
    move.specialMoveType = engineMove.getSpecialMoveType();
    move.playedAt = engineMove.getPlayedAt();
    return move;
};
export const toEngineMove = (move) => {
    if (move.specialMoveType) {
        return EngineMove.special(move.specialMoveType, move.playedAt);
    }
    return new EngineMove(move.row, move.col, move.playedAt);
};
