var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Column, Entity, ManyToOne, OneToOne, OneToMany, PrimaryGeneratedColumn, JoinColumn, Index, ManyToMany } from 'typeorm';
import { ColumnUUID } from '../custom-typeorm';
import Player from './Player';
import HostedGameOptions from './HostedGameOptions';
import Game from './Game';
import ChatMessage from './ChatMessage';
import HostedGameToPlayer from './HostedGameToPlayer';
import { Expose } from '../../../shared/app/class-transformer-custom';
import { Transform, Type } from 'class-transformer';
import Rating from './Rating';
let HostedGame = class HostedGame {
    constructor() {
        /**
         * gameData is null on server when game is not yet started.
         */
        this.gameData = null;
        /**
         * Whether there is a current player undo request.
         * Equals to the index of the player who asked for undo.
         */
        this.undoRequest = null;
        /**
         * Link to next game if this game has been rematched.
         */
        this.rematch = null;
        /**
         * Link to previous game if this game is a rematch.
         */
        this.rematchedFrom = null;
        this.createdAt = new Date();
    }
};
__decorate([
    PrimaryGeneratedColumn(),
    __metadata("design:type", Number)
], HostedGame.prototype, "id", void 0);
__decorate([
    ColumnUUID({ unique: true }),
    Expose(),
    __metadata("design:type", String)
], HostedGame.prototype, "publicId", void 0);
__decorate([
    ManyToOne(() => Player, { nullable: false }),
    Expose(),
    __metadata("design:type", typeof (_a = typeof Player !== "undefined" && Player) === "function" ? _a : Object)
], HostedGame.prototype, "host", void 0);
__decorate([
    OneToMany(() => HostedGameToPlayer, hostedGameToPlayer => hostedGameToPlayer.hostedGame, { cascade: true, persistence: false }),
    Expose(),
    Type(() => HostedGameToPlayer),
    __metadata("design:type", Array)
], HostedGame.prototype, "hostedGameToPlayers", void 0);
__decorate([
    Column({ length: 15 }),
    Index(),
    Expose(),
    __metadata("design:type", Object)
], HostedGame.prototype, "state", void 0);
__decorate([
    OneToOne(() => HostedGameOptions, hostedGameOptions => hostedGameOptions.hostedGame, { cascade: true }),
    Expose(),
    Type(() => HostedGameOptions),
    __metadata("design:type", typeof (_b = typeof HostedGameOptions !== "undefined" && HostedGameOptions) === "function" ? _b : Object)
], HostedGame.prototype, "gameOptions", void 0);
__decorate([
    Column({ type: 'json', transformer: { from: (value) => deserializeTimeControlValue(value), to: value => value } }),
    Expose(),
    Transform(({ value }) => deserializeTimeControlValue(value), { toClassOnly: true }),
    __metadata("design:type", Object)
], HostedGame.prototype, "timeControl", void 0);
__decorate([
    OneToMany(() => ChatMessage, chatMessage => chatMessage.hostedGame, { cascade: true }),
    Expose(),
    Type(() => ChatMessage),
    __metadata("design:type", Array)
], HostedGame.prototype, "chatMessages", void 0);
__decorate([
    OneToOne(() => Game, game => game.hostedGame, { cascade: true }),
    Expose(),
    Type(() => Game),
    __metadata("design:type", Object)
], HostedGame.prototype, "gameData", void 0);
__decorate([
    Column({ type: 'smallint', nullable: true }),
    Expose(),
    __metadata("design:type", Object)
], HostedGame.prototype, "undoRequest", void 0);
__decorate([
    OneToOne(() => HostedGame),
    JoinColumn(),
    Expose(),
    Type(() => HostedGame),
    __metadata("design:type", Object)
], HostedGame.prototype, "rematch", void 0);
__decorate([
    OneToOne(() => HostedGame),
    JoinColumn(),
    Expose(),
    Type(() => HostedGame),
    __metadata("design:type", Object)
], HostedGame.prototype, "rematchedFrom", void 0);
__decorate([
    Column({ type: Date, default: () => 'current_timestamp(3)', precision: 3 }),
    Expose(),
    Type(() => Date),
    __metadata("design:type", typeof (_d = typeof Date !== "undefined" && Date) === "function" ? _d : Object)
], HostedGame.prototype, "createdAt", void 0);
__decorate([
    ManyToMany(() => Rating, rating => rating.games),
    Expose(),
    __metadata("design:type", Array)
], HostedGame.prototype, "ratings", void 0);
HostedGame = __decorate([
    Entity()
], HostedGame);
export default HostedGame;
const deserializeTimeControlValue = (timeControlValue) => {
    if (null === timeControlValue) {
        return null;
    }
    timeControlValue.players.forEach(player => {
        if ('string' === typeof player.totalRemainingTime) {
            player.totalRemainingTime = new Date(player.totalRemainingTime);
        }
        if ('string' === typeof player.remainingMainTime) {
            player.remainingMainTime = new Date(player.remainingMainTime);
        }
    });
    return timeControlValue;
};
