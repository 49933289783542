import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "app-layout" };
const _hoisted_2 = { key: 0 };
import AppHeader from '@client/vue/components/layout/AppHeader.vue';
import AppFooter from './components/layout/AppFooter.vue';
import { useRouter } from 'vue-router';
export default /*@__PURE__*/ _defineComponent({
    __name: 'App',
    setup(__props) {
        const { currentRoute } = useRouter();
        const displayFooter = () => false !== currentRoute.value.meta.displayFooter;
        return (_ctx, _cache) => {
            const _component_router_view = _resolveComponent("router-view");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("header", null, [
                    _createVNode(AppHeader)
                ]),
                _createElementVNode("main", null, [
                    _createVNode(_component_router_view)
                ]),
                (displayFooter())
                    ? (_openBlock(), _createElementBlock("footer", _hoisted_2, [
                        _createVNode(AppFooter)
                    ]))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
