import { Parser } from 'expr-eval';
export class AbstractShadingPattern {
    constructor(size, options) {
        this.size = size;
        this.options = options;
    }
    /**
     * Distance to nearest side, useful to draw i.e rings.
     */
    distToSide(row, col) {
        return Math.min(row, col, this.size - row - 1, this.size - col - 1);
    }
}
export class NoShadingPattern extends AbstractShadingPattern {
    calc() {
        return 0;
    }
}
/**
 * Same as Hexdb theme: http://www.mseymour.ca/hexdb.html
 */
export class TriColorCheckerboard extends AbstractShadingPattern {
    calc(row, col) {
        return ((this.size + row - col + 1) % 3) / 2;
    }
}
/**
 * Same as Hexworld theme: https://hexworld.org/board/
 */
export class ConcentricalRings extends AbstractShadingPattern {
    calc(row, col) {
        return this.distToSide(row, col) % 2;
    }
}
export class Height5Lines extends AbstractShadingPattern {
    calc(row, col) {
        if (this.size < 9) {
            return 0;
        }
        const oppositeRow = this.size - row - 1;
        const oppositeCol = this.size - col - 1;
        if (4 === row || 4 === col || 4 === oppositeRow || 4 === oppositeCol) {
            return 1;
        }
        return 0;
    }
}
export class SingleRing extends AbstractShadingPattern {
    calc(row, col) {
        const height = Math.floor((this.size - 2) / 3);
        return this.distToSide(row, col) === height ? 1 : 0;
    }
}
/**
 * Allow players create their own pattern by entering a math expression
 * like: "((size + row - col) % 3) / 2"
 */
export class CustomShadingPattern extends AbstractShadingPattern {
    constructor(size, options) {
        super(size, options);
        this.size = size;
        this.options = options;
        this.expr = null;
        this.warnCooldown = null;
        if ('string' !== typeof this.options) {
            return;
        }
        try {
            const parser = new Parser();
            this.expr = parser.parse(this.options);
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.warn(`Expression syntax error: "${e.message}"`);
        }
    }
    calc(row, col) {
        if (null === this.expr) {
            return 0;
        }
        const distToSide = this.distToSide(row, col);
        const { size } = this;
        try {
            const result = this.expr.evaluate({
                row,
                col,
                size,
                distToSide,
                // Shortcuts
                r: row,
                c: col,
                s: size,
            });
            // Do not forget case when result is "NaN"
            if (result >= 0 && result <= 1) {
                return result;
            }
            if (result > 1) {
                return 1;
            }
            return 0;
        }
        catch (e) {
            this.warnThrottled(`Expression runtime error: "${e.message}" for row = ${row} and col = ${col}`);
            return 0;
        }
    }
    /**
     * Prevents showing bunch of warnings when calling calc() for each cell of the hex board
     */
    warnThrottled(...args) {
        if (null !== this.warnCooldown) {
            return;
        }
        this.warnCooldown = setTimeout(() => this.warnCooldown = null, 500);
        // eslint-disable-next-line no-console
        console.warn(...args);
    }
}
export const allShadingPatterns = [
    null,
    'tricolor_checkerboard',
    'concentrical_rings',
    'height_5_lines',
    'single_ring',
    'custom',
];
export const createShadingPattern = (name, size, option) => {
    if (!allShadingPatterns.includes(name)) {
        return new NoShadingPattern(size, option);
    }
    switch (name) {
        case 'tricolor_checkerboard': return new TriColorCheckerboard(size, option);
        case 'concentrical_rings': return new ConcentricalRings(size, option);
        case 'height_5_lines': return new Height5Lines(size, option);
        case 'single_ring': return new SingleRing(size, option);
        case 'custom': return new CustomShadingPattern(size, option);
        case null: return new NoShadingPattern(size, option);
    }
};
