var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import Player from './Player';
import { IsDate, IsNotEmpty, IsObject, IsString, Length } from 'class-validator';
import HostedGame from './HostedGame';
import { Expose } from '../class-transformer-custom';
import { Type } from 'class-transformer';
let ChatMessage = class ChatMessage {
};
__decorate([
    PrimaryGeneratedColumn(),
    __metadata("design:type", Number)
], ChatMessage.prototype, "id", void 0);
__decorate([
    IsNotEmpty(),
    Column(),
    __metadata("design:type", Number)
], ChatMessage.prototype, "hostedGameId", void 0);
__decorate([
    ManyToOne(() => HostedGame, hostedGame => hostedGame.chatMessages),
    JoinColumn(),
    __metadata("design:type", typeof (_a = typeof HostedGame !== "undefined" && HostedGame) === "function" ? _a : Object)
], ChatMessage.prototype, "hostedGame", void 0);
__decorate([
    Column({ nullable: true }),
    __metadata("design:type", Object)
], ChatMessage.prototype, "playerId", void 0);
__decorate([
    IsObject({ groups: ['post'] }),
    ManyToOne(() => Player),
    Expose(),
    __metadata("design:type", Object)
], ChatMessage.prototype, "player", void 0);
__decorate([
    IsString({ groups: ['playerInput', 'post'] }),
    Length(1, 255, { groups: ['playerInput', 'post'] }),
    Column(),
    Expose(),
    __metadata("design:type", String)
], ChatMessage.prototype, "content", void 0);
__decorate([
    IsDate({ groups: ['post'] }),
    Column({ default: () => 'current_timestamp(3)', precision: 3 }),
    Expose(),
    Type(() => Date),
    __metadata("design:type", typeof (_c = typeof Date !== "undefined" && Date) === "function" ? _c : Object)
], ChatMessage.prototype, "createdAt", void 0);
ChatMessage = __decorate([
    Entity()
], ChatMessage);
export default ChatMessage;
