var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Column, Entity, JoinColumn, OneToOne, PrimaryColumn } from 'typeorm';
import Player from './Player';
import { Expose } from '../class-transformer-custom';
import { IsBoolean, IsIn, IsNumber, IsOptional, IsString, Length, Max, Min, ValidateIf } from 'class-validator';
import { allShadingPatterns } from '../../../shared/pixi-board/shading-patterns';
let PlayerSettings = class PlayerSettings {
    constructor() {
        this.confirmMoveBlitz = false;
        this.confirmMoveNormal = false;
        this.confirmMoveCorrespondence = true;
        this.orientationLandscape = 11;
        this.orientationPortrait = 9;
        this.showCoords = false;
        this.show44dots = false;
        /**
         * Which shading pattern to use,
         * null for no shading pattern.
         */
        this.boardShadingPattern = null;
        /**
         * Which shading pattern to use,
         * null for no shading pattern.
         */
        this.boardShadingPatternOption = null;
        /**
         * Between 0 and 1,
         * 1 means shading pattern is fully contrasted,
         * 0 is not visible.
         */
        this.boardShadingPatternIntensity = 0.5;
    }
};
__decorate([
    PrimaryColumn(),
    __metadata("design:type", Number)
], PlayerSettings.prototype, "playerId", void 0);
__decorate([
    OneToOne(() => Player),
    JoinColumn(),
    __metadata("design:type", typeof (_a = typeof Player !== "undefined" && Player) === "function" ? _a : Object)
], PlayerSettings.prototype, "player", void 0);
__decorate([
    Expose(),
    IsOptional(),
    IsBoolean(),
    Column({ default: false }),
    __metadata("design:type", Boolean)
], PlayerSettings.prototype, "confirmMoveBlitz", void 0);
__decorate([
    Expose(),
    IsOptional(),
    IsBoolean(),
    Column({ default: false }),
    __metadata("design:type", Boolean)
], PlayerSettings.prototype, "confirmMoveNormal", void 0);
__decorate([
    Expose(),
    IsOptional(),
    IsBoolean(),
    Column({ default: true }),
    __metadata("design:type", Boolean)
], PlayerSettings.prototype, "confirmMoveCorrespondence", void 0);
__decorate([
    Expose(),
    IsOptional(),
    IsNumber(),
    Column({ type: 'smallint', default: 11 }),
    __metadata("design:type", Number)
], PlayerSettings.prototype, "orientationLandscape", void 0);
__decorate([
    Expose(),
    IsOptional(),
    IsNumber(),
    Column({ type: 'smallint', default: 9 }),
    __metadata("design:type", Number)
], PlayerSettings.prototype, "orientationPortrait", void 0);
__decorate([
    Expose(),
    IsOptional(),
    IsBoolean(),
    Column({ default: false }),
    __metadata("design:type", Boolean)
], PlayerSettings.prototype, "showCoords", void 0);
__decorate([
    Expose(),
    IsOptional(),
    IsBoolean(),
    Column({ default: false }),
    __metadata("design:type", Boolean)
], PlayerSettings.prototype, "show44dots", void 0);
__decorate([
    Expose(),
    IsOptional(),
    IsIn(allShadingPatterns),
    Column({ type: String, nullable: true, length: 64, default: null }),
    __metadata("design:type", Object)
], PlayerSettings.prototype, "boardShadingPattern", void 0);
__decorate([
    Expose(),
    IsOptional(),
    IsString(),
    Length(0, 255),
    ValidateIf((_, value) => value !== null),
    Column({ type: String, nullable: true, length: 255, default: null }),
    __metadata("design:type", Object)
], PlayerSettings.prototype, "boardShadingPatternOption", void 0);
__decorate([
    Expose(),
    IsOptional(),
    IsNumber(),
    Min(0),
    Max(1),
    Column({ type: 'float', default: 0.5 }),
    __metadata("design:type", Number)
], PlayerSettings.prototype, "boardShadingPatternIntensity", void 0);
PlayerSettings = __decorate([
    Entity()
], PlayerSettings);
export default PlayerSettings;
