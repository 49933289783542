var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Column, Entity, JoinColumn, OneToOne, PrimaryColumn } from 'typeorm';
import { Expose } from '../../../shared/app/class-transformer-custom';
import HostedGame from './HostedGame';
export const hasGameAnalyzeErrored = (gameAnalyze) => null !== gameAnalyze.endedAt && null === gameAnalyze.analyze;
let GameAnalyze = class GameAnalyze {
    constructor() {
        /**
         * If null but endedAt is not,
         * then the analyze errored.
         */
        this.analyze = null;
        /**
         * Analyze started at.
         */
        this.startedAt = new Date();
        /**
         * Analyze ended at.
         * If null, analyze is still processing,
         * should not query a new one,
         * unless started too long ago and job seems to have errored.
         */
        this.endedAt = null;
    }
};
__decorate([
    PrimaryColumn(),
    __metadata("design:type", Number)
], GameAnalyze.prototype, "hostedGameId", void 0);
__decorate([
    OneToOne(() => HostedGame),
    JoinColumn(),
    __metadata("design:type", typeof (_a = typeof HostedGame !== "undefined" && HostedGame) === "function" ? _a : Object)
], GameAnalyze.prototype, "hostedGame", void 0);
__decorate([
    Expose(),
    Column({ type: 'json', nullable: true }),
    __metadata("design:type", Object)
], GameAnalyze.prototype, "analyze", void 0);
__decorate([
    Expose(),
    Column({ type: Date, precision: 3, default: () => 'current_timestamp(3)' }),
    __metadata("design:type", typeof (_b = typeof Date !== "undefined" && Date) === "function" ? _b : Object)
], GameAnalyze.prototype, "startedAt", void 0);
__decorate([
    Expose(),
    Column({ type: Date, precision: 3, nullable: true }),
    __metadata("design:type", Object)
], GameAnalyze.prototype, "endedAt", void 0);
GameAnalyze = __decorate([
    Entity()
], GameAnalyze);
export default GameAnalyze;
