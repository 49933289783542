var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ValidatorConstraint } from 'class-validator';
import { HostedGameOptions } from '../models';
import { RANKED_BOARDSIZE_MAX, RANKED_BOARDSIZE_MIN } from '../ratingUtils';
/**
 * When enabling "ranked" game option, these validators check others options are eligible for a ranked game.
 * I.e setting boardsize=5 and ranked=true won't pass validation.
 */
class OptionsEligibleForRanked {
    validate(ranked, args) {
        if (!ranked) {
            return true;
        }
        const { object } = args;
        if (!(object instanceof HostedGameOptions)) {
            throw new Error('@OptionsEligibleForRanked expected to be used on an instance of HostedGameOptions');
        }
        return this.validateOptions(object);
    }
}
let BoardsizeEligibleForRanked = class BoardsizeEligibleForRanked extends OptionsEligibleForRanked {
    validateOptions(options) {
        return options.boardsize >= RANKED_BOARDSIZE_MIN && options.boardsize <= RANKED_BOARDSIZE_MAX;
    }
    defaultMessage() {
        return `Allowed board sizes for ranked games are ${RANKED_BOARDSIZE_MIN} to ${RANKED_BOARDSIZE_MAX}`;
    }
};
BoardsizeEligibleForRanked = __decorate([
    ValidatorConstraint({ name: 'boardsizeEligibleForRanked', async: false })
], BoardsizeEligibleForRanked);
export { BoardsizeEligibleForRanked };
let FirstPlayerEligibleForRanked = class FirstPlayerEligibleForRanked extends OptionsEligibleForRanked {
    validateOptions(options) {
        return null === options.firstPlayer;
    }
    defaultMessage() {
        return 'First player must be random for ranked games';
    }
};
FirstPlayerEligibleForRanked = __decorate([
    ValidatorConstraint({ name: 'firstPlayerEligibleForRanked', async: false })
], FirstPlayerEligibleForRanked);
export { FirstPlayerEligibleForRanked };
let SwapRuleEligibleForRanked = class SwapRuleEligibleForRanked extends OptionsEligibleForRanked {
    validateOptions(options) {
        return true === options.swapRule;
    }
    defaultMessage() {
        return 'Swap rule must be enabled for ranked games';
    }
};
SwapRuleEligibleForRanked = __decorate([
    ValidatorConstraint({ name: 'swapRuleEligibleForRanked', async: false })
], SwapRuleEligibleForRanked);
export { SwapRuleEligibleForRanked };
