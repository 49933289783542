var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Expose, GROUP_DEFAULT } from '../../../shared/app/class-transformer-custom';
import Player from './Player';
import { Column, Entity, JoinColumn, OneToOne, PrimaryColumn } from 'typeorm';
let AIConfig = class AIConfig {
};
__decorate([
    PrimaryColumn(),
    __metadata("design:type", Number)
], AIConfig.prototype, "playerId", void 0);
__decorate([
    OneToOne(() => Player, { cascade: ['insert', 'update'] }),
    JoinColumn(),
    Expose({ groups: [GROUP_DEFAULT, 'ai_config'] }),
    __metadata("design:type", typeof (_a = typeof Player !== "undefined" && Player) === "function" ? _a : Object)
], AIConfig.prototype, "player", void 0);
__decorate([
    Column({ type: String, length: 191 }),
    Expose({ groups: [GROUP_DEFAULT, 'ai_config'] }),
    __metadata("design:type", String)
], AIConfig.prototype, "engine", void 0);
__decorate([
    Column({ length: 32 }),
    Expose({ groups: [GROUP_DEFAULT, 'ai_config'] }),
    __metadata("design:type", String)
], AIConfig.prototype, "label", void 0);
__decorate([
    Column({ type: String, length: 64, nullable: true }),
    Expose({ groups: [GROUP_DEFAULT, 'ai_config'] }),
    __metadata("design:type", Object)
], AIConfig.prototype, "description", void 0);
__decorate([
    Column({ type: 'smallint', nullable: true }),
    Expose({ groups: [GROUP_DEFAULT, 'ai_config'] }),
    __metadata("design:type", Object)
], AIConfig.prototype, "boardsizeMin", void 0);
__decorate([
    Column({ type: 'smallint', nullable: true }),
    Expose({ groups: [GROUP_DEFAULT, 'ai_config'] }),
    __metadata("design:type", Object)
], AIConfig.prototype, "boardsizeMax", void 0);
__decorate([
    Column({ default: false }),
    Expose({ groups: [GROUP_DEFAULT, 'ai_config'] }),
    __metadata("design:type", Boolean)
], AIConfig.prototype, "requireMorePower", void 0);
__decorate([
    Column({ default: false }),
    Expose({ groups: [GROUP_DEFAULT, 'ai_config'] }),
    __metadata("design:type", Boolean)
], AIConfig.prototype, "isRemote", void 0);
__decorate([
    Column('json'),
    Expose({ groups: [GROUP_DEFAULT, 'ai_config'] }),
    __metadata("design:type", Object)
], AIConfig.prototype, "config", void 0);
__decorate([
    Column({ default: 0 }),
    __metadata("design:type", Number)
], AIConfig.prototype, "order", void 0);
AIConfig = __decorate([
    Entity()
], AIConfig);
export default AIConfig;
