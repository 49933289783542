/**
 * All existing rooms
 */
class Rooms {
}
/**
 * All open games meta data updates, like game created, started, ended (no moves).
 * Used to refresh games in lobby.
 */
Rooms.lobby = 'lobby';
/**
 * All players connection and disconnection.
 * Used to display all currently connected players.
 */
Rooms.onlinePlayers = 'online-players';
/**
 * Full game info (moves).
 * Used on game page.
 */
Rooms.game = (gameId) => `games/${gameId}`;
/**
 * Full game info for all games of a given player.
 * Used to know when a player needs to play on one of his games.
 */
Rooms.playerGames = (playerId) => `players/${playerId}/games`;
export default Rooms;
